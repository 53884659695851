import { moveItemInArray } from '@angular/cdk/drag-drop';
import { v1 as uuid } from 'uuid';

import { QuestionsActions, QuestionsActionTypes } from './questions.actions';
import { CallingState } from '../../state/app.state';
import { QuestionIndexOptions, QuestionType } from '@touch-apps/questions-model';

export interface QuestionsState {
  questionOptions: QuestionType[];
  questions: QuestionType[];
  callState: CallingState;
}

const initialState: QuestionsState = {
  questionOptions: [],
  questions: [],
  callState: CallingState.INIT,
};

export function questionsReducer(
  state = initialState,
  action: QuestionsActions
): QuestionsState {
  switch (action.type) {
    case QuestionsActionTypes.SET_QUESTION_OPTIONS:
      return {
        ...state,
        questionOptions: action.payload,
      };
    case QuestionsActionTypes.SET_QUESTIONS:
      return <QuestionsState>{
        ...state,
        questions: action.questions,
      };
    case QuestionsActionTypes.CLEAR_QUESTIONS:
      return {
        ...state,
        questions: [],
      };
    case QuestionsActionTypes.ADD_QUESTION:
      return {
        ...state,
        questions: AddAndReorderQuestions(
          state.questions,
          action.payload.type,
          action.payload.prevIndex,
          action.payload.currentIndex
        ),
      };
    case QuestionsActionTypes.UPDATE_QUESTION:
      console.log('update question payload', action.payload.question)
      return {
        ...state,
        questions: UpdateQuestions(state.questions, action.payload.question),
      };
    case QuestionsActionTypes.SORT_QUESTION:
      return {
        ...state,
        questions: ReorderQuestions(
          state.questions,
          action.payload.prevIndex,
          action.payload.currentIndex
        ),
      };
    case QuestionsActionTypes.REMOVE_QUESTION:
      return {
        ...state,
        questions: RemoveQuestion(state.questions, action.payload.index),
      };

    default: {
      return state;
    }
  }
}

const AddAndReorderQuestions = (
  questions: QuestionType[],
  questionType: QuestionType,
  prev: number,
  current: number
): QuestionType[] => {
  questionType.base.id = uuid();

  if (!questions) {
    questions = [];
  }
  const sortedQuestions = [...questions, questionType];
  if (current === QuestionIndexOptions.AtEnd) {
    questions = sortedQuestions;
    return questions;
  }
  if (prev === QuestionIndexOptions.AtEnd) {
    moveItemInArray(sortedQuestions, sortedQuestions.length - 1, current);
  } else {
    moveItemInArray(sortedQuestions, prev, current);
  }
  questions = sortedQuestions;

  return questions;
};

const ReorderQuestions = (
  questions: QuestionType[],
  prev: number,
  current: number
): QuestionType[] => {
  const reorderedQuestions = [...questions];
  moveItemInArray(reorderedQuestions, prev, current);
  questions = reorderedQuestions;

  return questions;
};

const UpdateQuestions = (
  questions: QuestionType[],
  questionToUpdate: QuestionType
): QuestionType[] => {
  return questions.map((questionInCollection: QuestionType) => {
    return questionInCollection.base.id === questionToUpdate.base.id
      ? questionToUpdate
      : questionInCollection;
  });
};

const RemoveQuestion = (
  questions: QuestionType[],
  index: number
): QuestionType[] => {
  const deletedQuestions = [...questions];
  deletedQuestions.splice(index, 1);
  questions = deletedQuestions;
  return questions;
};

// export const questionsEdited = (questions: QuestionType[] | undefined): boolean => {
//   if (questions === undefined){
//     return false
//   }
//   // @ts-ignore
//   return questions.some((questionInCollection: QuestionType) => {
//     return questionInCollection.base.isDirty === true;
//   });
// };
