import { Action } from '@ngrx/store';
import { Template } from '@touch-apps/templates-model';

export enum TemplateActionTypes {
  LOAD_TEMPLATES = '[Template] Load Templates',
  LOAD_TEMPLATES_SUCCESS = '[Template] Load Templates success',
  LOAD_TEMPLATES_FAILURE = '[Template] Load Templates failure',

  SET_TEMPLATE_ID_BEING_EDITED = '[Template] Set template id being edited',

  SAVE_TEMPLATE = '[Template] Save template being edited',
  SAVE_TEMPLATE_SUCCESS = '[Template] Save template success',
  SAVE_TEMPLATE_FAILURE = '[Template] Save template failure',

  DELETE_TEMPLATE = '[Template] Delete template',
  DELETE_TEMPLATE_SUCCESS = '[Template] Delete template success',

  CREATE_TEMPLATE = '[Template] Create template',
  CREATE_TEMPLATE_SUCCESS = '[Template] Create template success'
}

export class LoadTemplates implements Action {
  readonly type = TemplateActionTypes.LOAD_TEMPLATES;

  constructor() {
  }
}

export class LoadTemplatesSuccess implements Action {
  readonly type = TemplateActionTypes.LOAD_TEMPLATES_SUCCESS;

  constructor(public templates: Template []) {
  }
}

export class LoadTemplatesFailure implements Action {
  readonly type = TemplateActionTypes.LOAD_TEMPLATES_FAILURE;

  constructor(public error: any) {
  }
}

export class SetTemplateIdBeingEdited implements Action {
  readonly type = TemplateActionTypes.SET_TEMPLATE_ID_BEING_EDITED;

  constructor(public templateId: string) {
  }
}

export class SaveTemplate implements Action {
  readonly type = TemplateActionTypes.SAVE_TEMPLATE;

  constructor() {
  }
}

export class SaveTemplateSuccess implements Action {
  readonly type = TemplateActionTypes.SAVE_TEMPLATE_SUCCESS;

  constructor(public template: Template) {
  }
}

export class SaveTemplateFailure implements Action {
  readonly  type = TemplateActionTypes.SAVE_TEMPLATE_FAILURE;
  constructor(public error: any) {
  }
}

export class DeleteTemplate implements Action {
  readonly type = TemplateActionTypes.DELETE_TEMPLATE;

  constructor(public templateId: string) {
  }
}

export class DeleteTemplateSuccess implements Action {
  readonly type = TemplateActionTypes.DELETE_TEMPLATE_SUCCESS;

  constructor(public templateId: string) {
  }
}

export class CreateTemplate implements Action {
  readonly type = TemplateActionTypes.CREATE_TEMPLATE;

  constructor(public templateName: string, public templateDescription: string) {
  }
}

export class CreateTemplateSuccess implements Action {
  readonly type = TemplateActionTypes.CREATE_TEMPLATE_SUCCESS;

  constructor(public template: Template) {
  }
}

export type TemplateActions =
  | LoadTemplates | LoadTemplatesSuccess | LoadTemplatesFailure
  | SetTemplateIdBeingEdited
  | SaveTemplate | SaveTemplateSuccess | SaveTemplateFailure
  | DeleteTemplate | DeleteTemplateSuccess
  | CreateTemplate | CreateTemplateSuccess;


