
export enum QuestionTypes {
  TEXT = <any>'text',
  MEDIA = <any>'media',
  MULTI_CHOICE = <any>'multiChoice',
  CHECKBOX = <any>'checkBox',
  RADIO = <any>'radio',
  SWITCH = <any>'switch',
  DATETIME = <any>'dateTime',
  DRAWING = <any>'drawing',
  BARCODE = <any>'barcode',
  SIGNATURE = <any>'signature',
  INFORMATION = <any>'information',
  SLIDER = <any>'slider',
  RATING = <any>'rating',
  READING = <any>'reading',
}

export interface QuestionType {
  type: QuestionTypes;
  base: BaseQuestionProperties;
  icon: string;
  description: string;
  toolTip: string;
  note?: QuestionType;
  media?: QuestionType;
  questionProperties?:
    | TextProperties
    | MediaProperties
    | MultiChoiceProperties
    | CheckBoxProperties
    | RadioProperties
    | SwitchProperties
    | DateTimeProperties
    | DrawingProperties
    | BarcodeProperties
    | SignatureProperties
    | InformationProperties
    | SliderProperties
    | RatingProperties
    | RangeProperties
    | ReadingProperties;
}

export interface BaseQuestionProperties {
  id: string | 'uuid';
  name: string | 'Please enter a name';
  title: string | 'The question to ask';
  isRequired: boolean | false;
  isValid: boolean;
  isDirty: boolean;
}

export interface TextProperties {
  result: string;
  placeholder?: string | 'Enter a prompt for the user';
  validators?: any; // todo - will be an array of regex when implemented
}

export interface MediaProperties {
  result: MediaResult[];
}

export interface MediaResult {
  id?: string;  // 'uuid';
  key?: string; // 'Will be key of image in S3';
  mainUrl?: string;// 'Will be the signed url of the image';
  thumbUrl?: string; // 'Will be signed url of the image thumb';
  title?: string; // 'imageName';
  notes?: string; // 'Notes about the image';
}

export interface OptionSetting  {
  name: string;
  background?: string;
  foreground?: string;
}

export interface MultiChoiceProperties {
  optionName: string;
  choices: OptionSetting[];
  singleOrMulti: boolean;
  dropDown?: boolean;
  result: string[] | string;
}

export interface CheckBoxProperties {
  choices: OptionSetting[];
  hasNone: boolean;
  result: string[] | string;
}

export interface RadioProperties {
  choices: OptionSetting[];
  hasClear: boolean;
  result: string;
}

export interface SwitchProperties {
  result: boolean;
}

export interface DateTimeProperties {
  result: string; // Will be iso8601 string
  getDate: boolean;
  getTime: boolean;
}

export interface DrawingProperties {
  result: string;
}

export interface BarcodeProperties {
  result: string;
}

export interface SignatureProperties {
  result: SignatureResult;
  isTitleRequired: boolean;
  isNameRequired: boolean;
  isPositionRequired: boolean;
}

export interface SignatureResult {
  title?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  position?: string;
  signature?: string; // Will hold base64 of signature
}

export interface InformationProperties {
  information: string;
  result?: string;
}

export interface SliderProperties {
  result: number;
  rangeMin: number;
  rangeMax: number;
  step: number;
}

export interface RatingProperties {
  result: number;
  rangeMin: number;
  rangeMax: number;
}

export interface RangeProperties {
  result: number;
  rangeMax: number;
  rangeStep: RangeStep;
}

export interface ReadingProperties {
  result: number;
  units?: string;
  wholeDigits: number ;
  fractionDigits: number;
  lastFocusField?: number;
}

export enum RangeStep {
  HALF = <any>'Half',
  FULL = <any>'Full',
}

export enum QuestionIndexOptions {
  AtEnd = -1,
}

